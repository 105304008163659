import Glide from '@glidejs/glide'

export default function () {

  if (document.querySelectorAll('.glide').length > 0) {
    let playpause = document.getElementById('playpause');
    let period = 6000;

    let glide = new Glide('.glide', {
      type: 'carousel',
      gap: 0,
      autoplay: period,
      hoverpause: false
    })

    if (playpause) {

      playpause.addEventListener('click', function () {
        playpause.classList.toggle('paused');
        glide.update({autoplay: playpause.classList.contains('paused') ? false : period});
      });

      let stopAutoPlay = function () {
        if (!playpause.classList.contains('paused')) {
          playpause.classList.add('paused');
          playpause.checked = true; //playpause is a checkbox
          glide.update({autoplay: false});
        }
      }

      glide.on('swipe.start', stopAutoPlay);
      document.querySelector('.glide__prevnext button.prev').addEventListener('click', stopAutoPlay);
      document.querySelector('.glide__prevnext button.next').addEventListener('click', stopAutoPlay);

      let $playPauseContainer = jQuery('.glide__playpause');
      let addActive = () => $playPauseContainer.addClass('active');
      let removeActive = () => $playPauseContainer.removeClass('active');

      jQuery('#playpause')
        .focus(addActive)
        .focusout(removeActive)
      ;
    }

    glide.on(['build.after', 'run.after'], resetSlideAriaHidden);

    glide.mount();

    resetSlideAriaHidden();
  }

  /**
   * To support peeking at the next slide in the carousel, glide makes clone slides
   * unfortunately, those clones are read by screen readers
   * here, we remove those clones from the accessibility tool's view
   */
  function resetSlideAriaHidden() {
    setTimeout(() => {
      let $clones = jQuery('.glide__slide');
      $clones.attr('aria-hidden', "true");
      $clones.find('a, button').attr("tabindex", "-1");

      let $active = jQuery('.glide__slide--active');
      $active.removeAttr('aria-hidden');
      $active.find('a, button').removeAttr("tabindex");

      jQuery('br').attr('aria-hidden', "true");
    }, 500);
  }
}

