import moment from 'moment'

//endpoint to retrieve the array of posts
let endPoint = '/wp-json/wp/v2/posts?custom_max_per_page=500'; //see functions.php to increase this limit
//how many cards to load on each call to loadMore
let howManyToLoadDefault = 3;
let howManyMore = 3;
//keeps track of the number of cards loaded via lazy load
let numCardsLoadedFromLazyLoad = 0;
//which page all this stuff will happen on
let pageIdentifier = '#articleIndex';
//this variable will hold the template
let $cardTemplate = null;

let allPosts = null;

let searchResultsInitial = null;

function loadMore($, howManyMore = howManyToLoadDefault) {
  //if user is searching, then we ignore the load more action
  //search modes temporarily pauses the load more action
  if (!$('#searchArticles input').val() && getSelectedTags($).length === 0) {
    //determine where in the recipient array to begin pulling new cards
    let beginIndex = $('#searchResults .card').length;
    //determine where in the recipient array to end pulling new cards
    let endIndex = beginIndex + howManyMore - 1;
    //ensure we don't go output bounds
    endIndex = Math.min(allPosts.length - 1, endIndex);
    //insert all the cards in the DOM
    for (let x = beginIndex; x <= endIndex; x++) {
      makeCard($, allPosts[x]);
      //keep track of the number of cards loaded this way (used when getting out of search mode [see below])
      numCardsLoadedFromLazyLoad++;
    }
  }
}

//setup the tags for the search feature
//these tags are displayed underneath FILTER THE RESULTS
function setupTags($, data) {
  let allTags = [];
  data.forEach(element => allTags.push(...element.tag_names));

  //get rid of duplicates, sort, put pills in DOM
  [...new Set(allTags)]
    .sort()
    .forEach(element => {
      let $searchPill = $('<button class="btn pill dashicons-before dashicons-plus-alt2 tag-filter" aria-label="' + element + '">' + element + '</button>');
      $searchPill.click(e => {
        e.preventDefault();
        $searchPill.toggleClass('selected');
        doSearch($, data);
      });
      $(".tag-list").append($searchPill);
    });
}

function getSelectedTags($) {
  let selectedTags = [];
  $(".tag-filter.selected").each(function (index) {
    selectedTags.push($(this).text())
  });
  return selectedTags;
}

function setupCardTemplate($) {
  //find the card template
  let $templateInDom = $('.card');
  if ($templateInDom.length) {
    $templateInDom = $templateInDom.first();
    //clone it -- this will be the base for all future cards
    $cardTemplate = $templateInDom.clone();
    //remove the blank template from the DOM
    //$templateInDom.remove();
    //cleanup the in-memory template
    //$cardTemplate.attr('style', '');
  } else {
    alert('Could not create cards!');
  }

}

function makeCard($, post) {
  //clone the template (template is in memory, not in the DOM)
  let $node = $cardTemplate.clone();

  //fill in some of the basic stuff
  //accessibility: there was a ticket to make the headings on the "all articles" cards be h3 instead of h2
  //               this code solves that
  $node.find('.post-title').replaceWith(
    '<a class="post-title" href="' + post.link + '"><h3 class="h4 bolder black">' + post.title.rendered + '</h3></a>');

  $node.find('.top-part').css("background-image", "url('" + post.fimg_url + "')");
  $node.find('.date').text(moment(post.date).format("MMMM D, YYYY"));
  $node.find('.post-excerpt').html(post.excerpt.rendered);
  $node.find('.bottom-part .btn').attr('aria-label', "Click to see full article for post: " + post.title.rendered);
  $node.find('.bottom-part .btn').attr('href', post.link);

  $node.find('.top-part').empty();
  //this is for the pills INSIDE the cards
  post.tag_names.forEach(strTag => {
    let $pill = $('<a class="tag-pill">' + strTag + '</a>');
    $node.find('.top-part').append($pill);
  });

  $("#searchResults").append($node);
}

function setupCards($, postArr) {
  setupCardTemplate($);
  postArr.forEach(element => makeCard($, element));
}

//TODO import this from another file
//given a function, this method will debounce to ensure that function won't be called too often
function throttle(func, wait = 100) {
  let timer = null;
  return function (...args) {
    if (timer === null) {
      timer = setTimeout(() => {
        func.apply(this, args);
        timer = null;
      }, wait);
    }
  }
}

function revert($) {
  //at this point, we want to go back to the state the user was in before they typed into the search box
  //retain the number of cards loaded via lazy loaded
  let goBack = numCardsLoadedFromLazyLoad;
  //reset the num cards loaded
  numCardsLoadedFromLazyLoad = 0;
  //load this many cards
  loadMore($, goBack);
}

function doSearch($, data, query) {
  //delete all the existing cards
  $('#searchResults').empty();
  let selectedTags = getSelectedTags($);

  //if there's something in the box
  if (query || selectedTags.length) {
    let searchResults = [];
    //for each award recipients
    data
      //only keep posts that have all the selected tags
      .filter(post => selectedTags.reduce((acc, curr) => acc && post.tag_names.includes(curr), true))
      .forEach(post => {
        //determine if name is a close enough match
        if (query) {
          if (post.title.rendered.toLowerCase().indexOf(query) >= 0) {
            searchResults.push(post);
          }
        } else {
          searchResults.push(post);
        }
      });

    if (searchResults.length) {
      searchResults.forEach(post => makeCard($, post));
    } else {
      $('#searchResults').append('<h3 class="h3" style="margin-left: auto; margin-right: auto; text-align: center">No results found!</h3>');
    }

  } else { //this would happen if the user erased the search box
    revert($);
  }
}

//sets up the search listener
function setupSearch($, data) {

  searchResultsInitial = $("#searchResults").clone();

  //every time a user pressed a key, do the following
  let searchHandler = function (e) {
    //get the input and normalize it
    let query = e.target.value.toLowerCase().trim();
    doSearch($, data, query);
  };

  //debounce the function
  var throttledSearchHandler = throttle(searchHandler, 200);

  //every time a keyup happens, run the debounced function
  $('#searchArticles input')
    .on('search', throttledSearchHandler)
    .keyup(throttledSearchHandler);
}

function setupInfiniteScroll($) {
  $(window).scroll(function () {

    //figure out the current height of the page (page may get taller/shorter)
    let heightOfEntirePage = $(document).height();
    //figure out the current height of the viewport (viewport may change Ex: tablet rotate)
    let heightOfViewport = $(this).height();
    //determine how far down the scroll bar has moved
    let scrollBarPosition = $(this).scrollTop();
    //the only thing after the cards is the footer, so we take that in to consideration
    let heightOfFooter = $('.footer-wrapper').height();

    //if we scroll halfway into the footer, then load some more
    if (heightOfEntirePage - heightOfViewport - (heightOfFooter / 2) <= scrollBarPosition) {
      loadMore($);
    }
  });
}

export default function () {
  //whenever the page is ready
  jQuery(document).ready(function ($) {

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if ($(pageIdentifier).length) {
      //grab the JSON version of the page
      $.get(endPoint, function (data, status) {
        if (status === "success") {

          allPosts = data;

          setupTags($, data);
          setupCardTemplate($);
          setupSearch($, data);

          setupInfiniteScroll($);
          loadMore($);

          if (params.t) {
            $(".tag-list button").each(function() {
              let label = $(this).attr('aria-label');
              if (label && label.toLowerCase() === params.t.toLowerCase()) {
                $(this).trigger('click');
              }
            });
          }

        } else {
          alert('Could not load posts');
        }
      });
    }
  });
}