import {isArray} from "@glidejs/glide/src/utils/unit";

export default function () {

  jQuery(document).ready(function ($) {
    if ($('.page-template-team-application').length === 0) {
      return;
    }

    $.fn.isInViewport = function () {
      var elementTop = $(this).offset().top;
      var elementBottom = elementTop + $(this).outerHeight();

      var viewportTop = $(window).scrollTop();
      var viewportBottom = viewportTop + $(window).height();

      return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    let $dateSituation = $("input[name='date_situation']");
    let dateSituationMin = moment().subtract('60', 'd');
    let dateSituationMax = moment();

    $dateSituation.attr("min", dateSituationMin.format("YYYY-MM-DD"));
    $dateSituation.attr("max", dateSituationMax.format("YYYY-MM-DD"));

    $dateSituation.change(function (e) {
      let enteredMoment = moment(e.target.value);
      let lower = dateSituationMin.clone().toDate();
      let upper = dateSituationMax.clone().toDate();

      $("#date-situation-help-text")
        .toggleClass('required-label', !enteredMoment.isBetween(lower, upper, 'day', '[]'));
    });

    $("input, textarea, select").focus(function (e) {
      e.preventDefault();
      if (!$(this).isInViewport()) {
        e.target.scrollIntoView({
          block: 'center',
          behavior: "smooth"
        });
      }
    });

    $('.toggle-collapse a').click(function (e) {
      e.preventDefault();
      $('.more-copy').slideToggle();
      $(this).parent().toggleClass('expanded');
      $(this).text($(this).parent().hasClass('expanded') ? 'Read Less' : 'Read More');
    });

//     this whole "supporting documentation" section is fully custom
//     here, we insert a invisible upload input to let us utilize the built in browser validation for required elements
    function toggleDummyElement() {
      return;
      if ($(".file-upload-panel ul .filename").length) {
        $('.document-upload .dummy').remove();
      } else {
        if ($('.document-upload .dummy').length === 0) {
          $('.document-upload .file-upload-panel').prepend($('<input type="file" class="dummy" required>'));
        }
      }
    }

    toggleDummyElement();

    function getSumOfFileSizes() {
      let $allFileInputs = $(".file-upload-panel li input[type='file']");
      let size = 0;
      $allFileInputs.each(function () {
        size += (this.files.length > 0 ? this.files[0].size : 0);
      });
      return size;
    }

    function fileSizeCheckPassed() {
      let $errMessageSize = $('.file-upload-panel .error.size');

      //if sum of file sizes is above 25MB, reject
      if (getSumOfFileSizes() > 26214400) {
        $errMessageSize.slideDown();
        return false;
      } else {
        $errMessageSize.slideUp();
        return true;
      }
    }

    let $fileTypeError = $('.file-upload-panel .error.file-type');
    let myFileNumber = 1;
    $('.select-file').click(function (e) {
      e.preventDefault();
      $fileTypeError.slideUp();
      let tooManyFiles = $('.file-upload-panel ul li').length >= 5;
      let $errMessageFileCount = $('.file-upload-panel .error.file-count');
      if (tooManyFiles) {
        $errMessageFileCount.slideDown();
        return;
      } else {
        $errMessageFileCount.slideUp();
      }

      let fileID = "supporting-doc-" + myFileNumber;
      myFileNumber++;
      let $fileInput = $('<input type="file" />')
        .attr('id', fileID)
        .attr('name', "files[]")
        .attr("tabindex", "-1") //skip in tab order (off screen element)
        .css({'pointer-events': 'none'});

      let $domFile = $('<li>' +
        '<div class="filename"><span class="name">Select file</span></div>' +
        '<a href="#">Remove</a>' +
        '</li>');

      $domFile.find('.filename').prepend($fileInput)
      $domFile.find('.filename .name').click(function (e) {
        $fileInput.trigger('click');
      });

      $domFile.find("a").click(function (e) {
        e.preventDefault();
        $('.file-upload-panel .error.file-count').slideUp();
        $fileTypeError.slideUp();
        $('#' + fileID).remove();
        $(this).parent().remove();
        toggleDummyElement();
      })

      $domFile.hide();

      $('.file-upload-panel ul').append($domFile);
      $(".at-least-one").slideUp();

      $fileInput.click(function() {
        $fileTypeError.slideUp();
      });

      $fileInput.bind('change', function () {
        let $errMessageSize = $('.file-upload-panel .error.size');

        if (this.files[0].name.endsWith(".exe") || this.files[0].name.endsWith(".app")) {
          $fileTypeError.slideDown();
          $domFile.remove();
          return false;
        }

        //if sum of file sizes is above 25MB, reject
        if (!fileSizeCheckPassed()) {
          $domFile.remove();
          return false;
        } else {
          $domFile.show();
          $domFile.find('.filename .name').text(this.files[0].name);
          toggleDummyElement();
        }
      })

      $fileInput.trigger('click');
    });

    $('.amount-requested-input-wrapper input').on('input', (function (e) {
      let converted = parseFloat(e.target.value);
      let filteredValue = converted;
      if (converted > 5000)
        filteredValue = 5000;
      else if (converted < 0)
        filteredValue = 0;
      else if (isNaN(converted))
        filteredValue = "";
      else
        filteredValue = e.target.value;
      $(this).val(filteredValue);
    }));

    $('#team-member-application-form').submit(function (e) {
      let $panel = $('.file-upload-panel');
      let $requiredFile = $(".at-least-one");
      $requiredFile.slideUp();
      $fileTypeError.slideUp();

      let numFiles = 0;

      $(".file-upload-panel ul li input").each(function (index, $element) {
        if (this.files.length === 0) {
          $(this).parent().parent().remove();
        } else {
          numFiles += this.files.length;
        }
      });

      if (numFiles === 1) {
        e.preventDefault();
        $requiredFile.slideDown();
        $panel[0].scrollIntoView({
          block: 'center',
          behavior: "smooth"
        });
      }

    });
  });
  
}