
export default function () {
  const revealList = [];
  let waiting = false;
  let ticking = false;

  function isElementInView(el) {
    if (!el || !el.getBoundingClientRect) {
      return;
    }
    return el.getBoundingClientRect().top <= (window.innerHeight || document.documentElement.clientHeight) - 100
  }


  function reveal() {
    while(revealList.length && !waiting) {
      waiting = true;
      setTimeout(() => {
        waiting = false;
        reveal(); },
      500 );

      const revealEl = revealList.shift();
      revealEl.classList.add('impact-metrics__metric--appear-in');
    }
  }

  function checkForReveal() {
    document.querySelectorAll('.impact-metrics__metric:not([data-init-appear-in]').forEach((el) => {
      if (isElementInView(el) ) {
        el.dataset.initAppearIn = true;
        revealList.push(el);
      }
      reveal();
    });
  }

  window.addEventListener('scroll', () => {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        checkForReveal();
        ticking = false;
      });

      ticking = true;
    }
  });
}
