import { toggleBodyScrolling, trapFocus } from "../helpers";

export default function () {
    let modalTriggerEls = document.querySelectorAll('[data-modal]');

    modalTriggerEls.forEach(modalTriggerEl => {
        const modalWrapperEl = document.querySelector('#' + modalTriggerEl.dataset.modal);
        const modalCloseEl = modalWrapperEl.querySelector('[data-close]');
        const videoEl = modalWrapperEl.querySelector('video');

        // Move modal directly under body to avoid z-index layer issue
        document.body.appendChild(modalWrapperEl);

        modalTriggerEl.addEventListener('click', () => {
            openModal();
        })
        
        modalCloseEl.addEventListener('click', () => {
            closeModal();
        });

        [videoEl, modalWrapperEl].forEach(el => {
            el.addEventListener('keyup', (e) => {
                if (e.which === 27) {
                    closeModal();
                }
            }, { capture: true }, true);
        });
        
        modalWrapperEl.addEventListener('click', (e) => {
            if (!e.target.closest('.modal-body')) {
                closeModal();
            }
        });

        trapFocus(modalWrapperEl);

        function openModal() {
            if (!modalWrapperEl.classList.contains('active')) {
                toggleBodyScrolling();
                modalWrapperEl.classList.add('active');
                modalCloseEl.focus();
            }
        }
        
        function closeModal() {
            if (modalWrapperEl.classList.contains('active')) {
                toggleBodyScrolling();
                modalWrapperEl.classList.remove('active');
                videoEl.pause();
                modalTriggerEl.focus();
                setTimeout(() => {
                    modalTriggerEl.focus();
                }, 50);
            }
        }
    });
}
