export default function () {
  const accordions = document.querySelectorAll('[data-accordion]');
  
  Array.from(accordions).forEach((accordionEl) => {
    if (!accordionEl.dataset.initAccordion) {
      accordionEl.dataset.initAccordion = true;

      const accordionItemEls = accordionEl.children;
      Array.from(accordionItemEls).forEach((accordionItemEl) => {
        const triggers = accordionItemEl.querySelectorAll('.accordion__trigger');

        Array.from(triggers).forEach(trigger => {
          adjustTriggerSemanticsForBreakpoint(trigger);
        
          window.addEventListener('resize', () => {
            adjustTriggerSemanticsForBreakpoint(trigger);
          });

          // Toggle expanded on click
          trigger.addEventListener('click', () => {
            // Only allow expand/collapse for mobile
            if (window.innerWidth < 1024 ) {
              // For all accordion items, decide if we are closing or opening it
              Array.from(accordionItemEls).forEach((accordionItemElChanging) => {
                const accordionTriggerElChanging = accordionItemElChanging.querySelector('.accordion__trigger');
                if (accordionItemElChanging !== accordionItemEl
                    || accordionItemEl.classList.contains('accordion--open')) {
                  accordionItemElChanging.classList.remove('accordion--open');
                  accordionTriggerElChanging.setAttribute('aria-expanded', false);
                } else {
                  accordionItemElChanging.classList.add('accordion--open')
                  accordionTriggerElChanging.setAttribute('aria-expanded', true);
                }
              });
            }
          });
        });
      });
    }
  });
}

function adjustTriggerSemanticsForBreakpoint(triggerEl) {
  if (window.innerWidth < 1024 ) {
    triggerEl.removeAttribute('disabled');
    triggerEl.setAttribute('aria-expanded', false);
  } else {
    triggerEl.setAttribute('disabled', true);
    triggerEl.removeAttribute('aria-expanded');
  }
}