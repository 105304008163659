/** configuration and shared resources **/

//Array of award recipient objects
let awardRecipients = null;
//endpoint to retrieve the array of award recipients
let endPoint = '/wp-json/wp/v2/pages/857';
//how many cards to load on each call to loadMore
let howManyToLoadDefault = 3;
//keeps track of the number of cards loaded via lazy load
let numCardsLoadedFromLazyLoad = 0;
//which page all this stuff will happen on
let pageIdentifier = 'body.page-id-857';
//this variable will hold the template provided by grant-recipients.blade.php
let $template = null;

export default function () {
    //whenever the page is ready
    jQuery(document).ready(function () {
        //if we are on the grand recipients page
        if (jQuery(pageIdentifier).length) {
            //grab the JSON version of the page
            jQuery.get(endPoint, function (data, status) {
                //if we successfully retrieved the data
                if (status === "success") {
                    //dig down to find the advanced custom field we want
                    awardRecipients = data.ACF.flexible_content[1].award_recipients;

                    //find the card template from grant-recipients.blade.php
                    let $templateInDom = jQuery('.recipient-card-template');
                    //clone it -- this will be the base for all future cards
                    $template = $templateInDom.clone();
                    //remove the blank template from the DOM
                    $templateInDom.remove();
                    //cleanup the in-memory template
                    $template.removeClass('recipient-card-template');
                    $template.attr('style', '');

                    //do an initial load
                    loadMore();
                    //listen for keypresses in the search bar
                    setupSearch();

                    //every time we scroll, do the following
                    jQuery(window).scroll(function () {

                        //figure out the current height of the page (page may get taller/shorter)
                        let heightOfEntirePage = jQuery(document).height();
                        //figure out the current height of the viewport (viewport may change Ex: tablet rotate)
                        let heightOfViewport = jQuery(this).height();
                        //determine how far down the scroll bar has moved
                        let scrollBarPosition = jQuery(this).scrollTop();
                        //the only thing after the cards is the footer, so we take that in to consideration
                        let heightOfFooter = jQuery('.footer-wrapper').height();

                        //if we scroll halfway into the footer, then load some more
                        if (heightOfEntirePage - heightOfViewport - (heightOfFooter / 2) <= scrollBarPosition) {
                            loadMore();
                        }
                    });
                } else { //if the server somehow didn't response
                    alert('Could not load grant recipients');
                }
            })
        }
    })
}

//delete all the existing cards
function removeAllCards() {
    return jQuery('.flip-card.award').remove();
}

//returns the number of cards
function getNumCards() {
    return jQuery('.flip-card.award').length;
}

//gets the contents of the search box
function getSearchInput() {
    return jQuery('#searchCharities').val();
}

//return true if the search box is empty
function searchInputIsEmpty() {
    return !getSearchInput();
}

//inserts some more cards in to the DOM if possible
function loadMore(howManyMore = howManyToLoadDefault) {
    //if user is searching, then we ignore the load more action
    //search modes temporarily pauses the load more action
    if (searchInputIsEmpty()) {
        //determine where in the recipient array to begin pulling new cards
        let beginIndex = getNumCards();
        //determine where in the recipient array to end pulling new cards
        let endIndex = beginIndex + howManyMore - 1;
        //ensure we don't go output bounds
        endIndex = Math.min(awardRecipients.length - 1, endIndex);
        //insert all the cards in the DOM
        for (let x = beginIndex; x <= endIndex; x++) {
            jQuery("#awardsContainer").append(makeCard(awardRecipients[x]));
            //keep track of the number of cards loaded this way (used when getting out of search mode [see below])
            numCardsLoadedFromLazyLoad++;
        }
    }
}

//given a function, this method will debounce to ensure that function won't be called too often
function throttle(func, wait = 100) {
    let timer = null;
    return function (...args) {
        if (timer === null) {
            timer = setTimeout(() => {
                func.apply(this, args);
                timer = null;
            }, wait);
        }
    }
}

//sets up the search listener
function setupSearch() {

    //every time a user pressed a key, do the following
    var searchHandler = function (e) {
        //get the input and normalize it
        let query = e.target.value.toLowerCase().trim();
        //delete all the existing cards
        removeAllCards();
        //if there's something in the box
        if (query) {
            //get the card container
            let $container = jQuery("#awardsContainer");
            //for each award recipients
            awardRecipients.forEach(awardRecipient => {

                //determine if name is a close enough match
                let c1 = awardRecipient.name.toLowerCase().indexOf(query) >= 0;
                //determine if sponsor is a close enough match
                let c2 = awardRecipient.franchisee_sponsor.toLowerCase().indexOf(query) >= 0;
                //if we found something
                if (c1 || c2) {
                    //insert the card
                    $container.append(makeCard(awardRecipient));
                }
            });
        } else { //this would happen if the user erased the search box
            //at this point, we want to go back to the state the user was in before they typed into the search box
            //retain the number of cards loaded via lazy loaded
            let goBack = numCardsLoadedFromLazyLoad;
            //reset the num cards loaded
            numCardsLoadedFromLazyLoad = 0;
            //load this many cards
            loadMore(goBack);
        }
    };

    //debounce the function
    var throttledSearchHandler = throttle(searchHandler, 200);

    //every time a keyup happens, run the debounced function
    document.getElementById("searchCharities").addEventListener('keyup', throttledSearchHandler);
}

//make a brand new card and returns it
function makeCard(award) {

    //clone the template (template is in memory, not in the DOM)
    let node = $template.clone();

    //fill in some of the basic stuff
    node.find('.charity-logo').css("background-image", "url('" + award.award_image + "')");
    node.find('.charity-name').text(award.name);
    node.find('.charity-date').text(award.Date);
    node.find('.more-info').attr('aria-label', "Click to see more info about " + award.name);
    node.find('.charity-description').text(award.description);

    //charity link is optional
    let recipientLink = node.find('.charity-link.recipient-location');
    if (award.charity_website_url) { //if exists
        recipientLink.attr('href', award.charity_website_url);
    } else {
        recipientLink.remove(); //delete if doesn't exist
    }

    //charity sponsor is also optional
    let charitySponsor = node.find('.charity-sponsor');
    if (charitySponsor) {
        charitySponsor.text(award.franchisee_sponsor);
    } else {
        charitySponsor.remove();
    }

    //fill in some more basic info
    let associatedWingstop = node.find('.charity-link.wingstop-location');
    associatedWingstop.attr('href', award.link_to_wingstop_location);
    associatedWingstop.attr('aria-label', award.wingstop_location + ". Opens in new window.");
    associatedWingstop.text(award.wingstop_location);

    node.find('.charity-address').text(award.address_for_wingstop_location);

    //setup the more-info button (flips cards over)
    node.find('.more-info').click(function () {
        let parent_node = this.parentNode.parentNode.parentNode;
        parent_node.classList.toggle('is-flipped');

        parent_node.children[0].setAttribute('aria-hidden', 'true');
        parent_node.children[1].setAttribute('aria-hidden', 'false');

        let tabindexSwitcher = parent_node.children[1].querySelectorAll('[tabindex="-1"]');
        tabindexSwitcher.forEach(el => {
            el.setAttribute('tabindex', '0');
        });
    })

    //setup the close icon (flips card back to front)
    node.find('.close').click(function () {
        let parent_node = this.parentNode.parentNode;
        parent_node.classList.toggle('is-flipped');

        parent_node.children[0].setAttribute('aria-hidden', 'false');
        parent_node.children[1].setAttribute('aria-hidden', 'true');

        let tabindexSwitcher = parent_node.children[1].querySelectorAll('[tabindex="0"]');
        tabindexSwitcher.forEach(el => {
            el.setAttribute('tabindex', '-1');
        })
    })

    return node;
}
