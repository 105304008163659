import hero from "./components/hero";
import nav from "./components/nav";
import modal from "./components/modal";
import validation from "./components/validation";
import polyfills from "./polyfills";
import grantrecipients from "./components/grant-recipients";
import contact from "./components/contact";
import postSearch from "./components/post-search";
import accordion from "./components/core-programs";
import revealAnimation from "./components/impact-metrics";

//pages
import teamApplication from './pages/team-application.js?'; 


function main() {
  polyfills();

  const components = [
    hero,
    nav,
    modal,
    validation,
    grantrecipients,
    contact,
    postSearch,
    teamApplication,
    accordion,
    revealAnimation,
  ];

  components.forEach(init => {
    init();
  });
}

document.addEventListener("DOMContentLoaded", function () {
  main();

  //accessibility - include the top-level menu in the sub menu
  jQuery("ul.sub-menu").each(function (index) {
    let sectionLabel = jQuery(this).prev().text();
    jQuery(this).find("a").each(function (index) {
      jQuery(this).attr("aria-label", sectionLabel + " - " + jQuery(this).text());
    });
  });

  //accessibility - if button is clicked, move to #main-container
  //                button tag must be used because anchor doesn't support spacebar press
  jQuery('#skip-main-button').click(function (e) {
    e.preventDefault();
    let anchor = "main-container";
    let loc = document.location.toString().split('#')[0];
    document.location = loc + '#' + anchor;
  });
});

