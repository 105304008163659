import { firstFocusableElement, lastFocusableElement, toggleBodyScrolling } from "../helpers";

export default function () {
    let last_known_scroll_position = 0;
    let ticking = false;
    let navbar = document.querySelector('header.navbar-wrapper');
    let hamburger = navbar.querySelector('.hamburger');
    let hamburgerBox = navbar.querySelector('.hamburger-box');
    let mobile_nav = navbar.querySelector('.mobile-nav');
    let menuItemsWithChildren = navbar.querySelectorAll('.menu-item-has-children');

    // Floating header
    function toggleNavDock(scroll_pos) {
        if(scroll_pos > 60) {
            navbar.classList.add('undocked')
        } else {
            navbar.classList.remove('undocked')
        }
    }

    window.addEventListener('scroll', () => {
        last_known_scroll_position = window.scrollY || window.pageYOffset;

        if (!ticking) {
            window.requestAnimationFrame(() => {
                toggleNavDock(last_known_scroll_position);
                ticking = false;
            });

            ticking = true;
        }
    });
    
    // Desktop keyboard navigation
    menuItemsWithChildren.forEach((menuItemWithChildren) => {
        menuItemWithChildren.addEventListener('focusin', () => {
            menuItemWithChildren.classList.add('open');
        });
        menuItemWithChildren.addEventListener('focusout', (e) => {
            if (!e.relatedTarget || e.relatedTarget.closest('.menu-item-has-children') !== menuItemWithChildren) {
                menuItemWithChildren.classList.remove('open');
            }
        });
    });

    // Mobile Nav
    hamburger.addEventListener('click', toggleMobileNav);

    document.body.addEventListener('click', (e) => {
        if (!e.target.closest('header')) {
            closeMobileNav();
        }
    });
    
    lastFocusableElement(mobile_nav).addEventListener('focusout', (e) => {
        if (!e.relatedTarget.closest('.mobile-nav')) {
            toggleMobileNav();
        }
    });
    firstFocusableElement(mobile_nav).addEventListener('focusout', (e) => {
        if (!e.relatedTarget.closest('.mobile-nav')) {
            toggleMobileNav();
        }
    });

    function toggleMobileNav() {
        const toggleFlag = !mobile_nav.classList.contains('is-active');
        if (toggleFlag) {
            openMobileNav()
        } else {
            closeMobileNav()
        }
    }
    function openMobileNav() {
        if (!hamburgerBox.classList.contains('is-active')) {
            hamburgerBox.classList.add('is-active');
            mobile_nav.classList.add('is-active');
            document.body.classList.add('menu-open');
            toggleBodyScrolling();
            hamburger.setAttribute('aria-expanded', true);
        }
    }
    function closeMobileNav() {
        if (hamburgerBox.classList.contains('is-active')) {
            hamburgerBox.classList.remove('is-active');
            mobile_nav.classList.remove('is-active');
            document.body.classList.remove('menu-open');
            toggleBodyScrolling();
            hamburger.setAttribute('aria-expanded', false);
        }
    }
}
