
function focusableElements($parentEl) {
  return $parentEl.querySelectorAll('button, [href], input, video, select, textarea, [tabindex]:not([tabindex="-1"])');
}

export function firstFocusableElement($parentEl){
  const $focusableEls = focusableElements($parentEl);
  if ($focusableEls.length) {
    return $focusableEls[0];
  }
  return null;
}

export function lastFocusableElement($parentEl) {
  const $focusableEls = focusableElements($parentEl);
  if ($focusableEls.length) {
    return $focusableEls[$focusableEls.length - 1];
  }
  return null;
}

export function trapFocus(el) {
  const first = firstFocusableElement(el);
  const last = lastFocusableElement(el);
  const overflowEl = document.createElement('div');
  overflowEl.setAttribute('tabindex','0');
  el.appendChild(overflowEl);

  // Record tab direction
  let isShift = false;

  const keydownHandler = (e) => {
    // Tab press
    if (e.which === 9) {
      isShift = e.shiftKey;
    }
  };
  const firstBlurHandler = () => {
    if (isShift) {
      last.focus();
    }
  };
  const lastBlurHandler = (e) => {
    if (!isShift) {
      e.preventDefault();
      first.focus();
    }
  };

  el.addEventListener('keydown', keydownHandler);
  first.addEventListener('blur', firstBlurHandler);
  last.addEventListener('blur', lastBlurHandler);

  return () => {
    if (last) {
      last.removeEventListener('blur', lastBlurHandler);
    }
    if (first) {
      first.removeEventListener('blur', firstBlurHandler);
    }
    if (el) {
      el.removeEventListener('keydown', keydownHandler);
      if (overflowEl) {
        overflowEl.remove();
      }
    }
  };
}

let scrollPositionAtOpen = 0;
export function toggleBodyScrolling(flag) {
  let bodyEl = document.body,
    mainEl = document.querySelector('main');

  let toggleFlag = flag !== undefined ? flag : !bodyEl.classList.contains('no-scroll')

  if (toggleFlag) {
    scrollPositionAtOpen = window.scrollY || window.pageYOffset;
    bodyEl.classList.add('no-scroll');
    mainEl.style.top = `-${scrollPositionAtOpen}px`;
  } else {
    mainEl.style.top = null;
    bodyEl.classList.remove('no-scroll');
    window.scrollTo(0, scrollPositionAtOpen);
  }
}
