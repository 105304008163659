function toggleInputsAsNeeded(containerSelector, show) {
    //find the container that holds the input
    let container = jQuery(containerSelector);
    //show/hide the div as appropriate
    container.attr("class", show ? "show" : "");
    //require or not as appropriate
    container.find("input").prop('required', show);
}

export default function () {

    let employee = document.getElementById('employeeType');

    if (employee) {
        employee.onchange = function () {
            var selectedEmployeeType = this[this.selectedIndex].value;
            toggleInputsAsNeeded("#restaurantNum", selectedEmployeeType === "team-member");
            toggleInputsAsNeeded("#companyName", selectedEmployeeType === "brand-partner-org");
        }
    }
}


